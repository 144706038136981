import {
  type LoopPostReturnType,
  getHomepageLoopCatPosts,
  getHomepageLoopPosts,
  getLoops,
  getLoopsCat,
} from "lib/db/loops";
import {
  type NextPage,
  type GetStaticPropsContext,
  type InferGetStaticPropsType,
} from "next";
import Image from "next/image";
import CarouselLoop from "~/components/CarouselLoop";
import Layout from "~/components/Layout";
import Link from "next/link";
import { getPostsMetadata, getThumbnail } from "~/lib/db/posts";
import { type ArticlePreviewType } from "~/types";
import { postExcerpt } from "~/utils";
import Rubriche from "~/components/loop/Rubriche";
import ContenutiAperti from "~/components/loop/ContenutiAperti";
import TitleLoop from "~/components/TitleLoop";
import gommaCarboncino from "@public/gomma-carboncinoSBS.jpg";
import PreviewspecificArticle from "~/components/ArticlePreview/PreviewSpecificArticle";
import { ArrowRight, ArrowRightLong } from "~/components/ArrowComponents";
import EvidenzaLoop from "~/components/loop/EvidenzaLoop";
import {
  gePreviousNumbersForHome,
  getArticleFromNumber,
  getImgageCurrentNumbers,
  getInfoCurrentNumbers,
} from "~/lib/db/previousNumbers";
import NumeroCorrente from "~/components/loop/NumeroCorrente";
import { getAllSpecialiForHome, type InfoSpeciali } from "~/lib/db/speciali";
import CarouselLoopSpeciale from "~/components/CarouselLoopSpeciale";
import { useEffect, useState } from "react";
import { LoadingCarousel } from "~/components/Loading";
const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  evidenza,
  numeroCorrente,
  studio,
  speciali,
  parole,
  approfondimenti,
  interviste,
  letture,
  recensioni,
  proposteDiPace,
  contenutiAperti,
  chiaviDiSvolta,
  insegnamentiOrali,
  editoriale,
  infoCurrentNumber,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);
  return (
    <>
      <Layout>
        <section className="lg:pb-16 print:hidden">
          <div className="mx-auto max-w-box">
            <TitleLoop
              titleLoop={"In evidenza dal nostro archivio"}
              customClass={
                "my-auto px-4 pb-4 text-xl sm:text-3xl font-bold text-black md:py-4"
              }
            />
          </div>
          <div className="carousel-wrapper feat-carousel pt-8">
            {loading ? (
              <LoadingCarousel />
            ) : (
              <EvidenzaLoop evidenzalLoop={evidenza} />
            )}
          </div>
        </section>
        <section className="mb-28 mt-20 flex w-full flex-col lg:flex-row lg:gap-10 xl:mb-52 xl:mt-20 print:my-4">
          <div className="numeroCorrenteMain mb-12 w-full px-4 lg:flex lg:basis-[50%] lg:flex-col">
            <div>
              <Link href={`/n/${infoCurrentNumber.slug}`}>
                <TitleLoop
                  titleLoop={infoCurrentNumber.slug}
                  customClass={
                    "inline text-[38px] sm:text-[3.2rem] text-[#7b7f86] hover:text-orange transition-all font-bold uppercase min-[1243px]:px-0 print:text-4xl"
                  }
                />
              </Link>
              <p className="-mt-2 max-w-box text-lg font-medium uppercase leading-[32px] text-[#90939a] sm:text-[22px] min-[1243px]:px-0 print:text-xl">
                Il numero in corso
                <br />
                <span className="lowercase">
                  {infoCurrentNumber.description}
                </span>
              </p>
            </div>
            <NumeroCorrente numeroCorrente={numeroCorrente} />
          </div>
          {infoCurrentNumber.img != null && (
            <div className="pl-4 lg:flex lg:basis-[50%] lg:justify-end">
              <figure className="w-full rounded-l-[60px] bg-lightLightGray sm:rounded-l-[100px]">
                <Image
                  width={749}
                  height={351}
                  src={infoCurrentNumber.img}
                  alt={infoCurrentNumber.description}
                  className="aspect-square w-full rounded-l-[60px] object-cover mix-blend-multiply sm:rounded-l-[100px] lg:sticky lg:top-[105px]"
                />
              </figure>
            </div>
          )}
        </section>
        <section className="bg-[#4e5460] py-20 lg:pt-28 print:break-before-avoid print:py-10">
          <div className="mx-auto max-w-box px-4">
            <TitleLoop
              titleLoop={"Lo studio mensile"}
              customClass={
                "mb-20 lg:mb-32 text-3xl sm:text-4xl font-bold text-white md:text-6xl"
              }
            />
            <PreviewspecificArticle article={studio} />
            <ArrowRight
              href={"/c/lo-studio"}
              color={"white"}
              message={"Tutte le lezioni"}
              divStyles={"flex"}
            />
            <hr className="my-24 text-white max-sm:my-16" />
            <div>
              <TitleLoop
                titleLoop={"La raccolta degli insegnamenti orali"}
                customClass={"text-2xl text-orange uppercase lg:text-3xl"}
              />
              <p className="mb-6 mt-4 text-xl font-bold text-white lg:text-2xl">
                Opera completa
              </p>
              <ArrowRightLong
                href={`/c/${insegnamentiOrali.slug}`}
                color={"white"}
                message={"Scopri"}
                divStyles={"flex"}
              />
            </div>
          </div>
        </section>
        <section className="py-20 lg:py-28 print:hidden">
          <div className="w-full">
            <TitleLoop
              titleLoop={"Gli speciali"}
              customClass={
                "mb-20 lg:mb-32 text-3xl sm:text-4xl text-center font-bold text-black md:text-6xl"
              }
            />
          </div>
          <div className="carousel-wrapper print:hidden">
            <CarouselLoopSpeciale carouselLoop={speciali} />
          </div>
          <ArrowRight
            href={`/speciali`}
            color={"black"}
            message={"Tutti gli speciali"}
            divStyles={"justify-center flex max-sm:pt-6 pt-14"}
          />
        </section>
        <section className="bg-[#e5e6e7] py-20 lg:py-28 print:hidden">
          <div className="mx-auto max-w-box px-4 lg:w-1/2">
            <TitleLoop
              titleLoop={"Gli editoriali"}
              customClass={
                "mb-10 sm:mb-20 lg:mb-32 text-3xl sm:text-4xl sm:text-center font-bold text-black md:text-6xl"
              }
            />
            <p className="text-gray sm:text-center">
              Gli editoriali di Minoru Harada, attuale presidente della Soka
              Gakkai, e di Daisaku Ikeda, presidente della Soka Gakkai
              Internazionale, tradotti dal mensile giapponese Daibyakurenge.
            </p>
            <ArrowRight
              href={`/c/${editoriale.slug}`}
              color={"black"}
              message={"Tutti gli editoriali"}
              divStyles={"sm:justify-center flex pt-12"}
            />
          </div>
        </section>
        <section className="py-20 lg:py-28 print:hidden">
          <div className="w-full">
            <TitleLoop
              titleLoop={"Le interviste"}
              customClass={
                "mb-20 lg:mb-32 text-3xl sm:text-4xl text-center font-bold text-black md:text-6xl"
              }
            />
          </div>
          <div className="carousel-wrapper print:hidden">
            <CarouselLoop carouselLoop={interviste} />
          </div>
          <ArrowRight
            href={`/c/intervista`}
            color={"black"}
            message={"Vedi tutte le interviste"}
            divStyles={"justify-center flex  max-sm:pt-6 pt-14"}
          />
        </section>
        <section className="bg-[#eaebec] pb-28 pt-24 print:pb-10 print:pt-10">
          <div className="mx-auto max-w-section px-4">
            <TitleLoop
              titleLoop={"Rubrica"}
              customClass={
                "entry-title text-lightGray text-sm uppercase tracking-[3px] pb-4 font-medium block"
              }
            />
          </div>
          <div className="relative mx-auto max-w-section px-4 after:border-lightGray md:after:absolute md:after:bottom-0 md:after:left-2/4 md:after:top-0 md:after:w-2 md:after:translate-x-[calc(50%)] md:after:border-l md:after:content-[''] lg:pb-16 print:after:hidden">
            <div className="mx-auto grid grid-cols-1 gap-y-16  md:grid-cols-2 md:gap-x-24 lg:gap-x-48 print:grid-cols-1">
              <TitleLoop
                titleLoop={"Le parole del Buddismo"}
                customClass={
                  "order-1 text-3xl font-bold text-black print:order-1"
                }
              />
              <Rubriche
                rubrica={parole}
                nameRubrica={"Le parole del Buddismo"}
                slug={"/c/le-parole-del-buddismo"}
                customClass={
                  "order-2 md:order-3 md:border-b-0 border-b border-lightGray pb-16 md:pb-0 print:order-2"
                }
              />
              <TitleLoop
                titleLoop={"Gli approfondimenti per lo studio del Buddismo"}
                customClass={
                  "order-3 font-bold text-black text-3xl md:order-2 print:order-3"
                }
              />
              <Rubriche
                rubrica={approfondimenti}
                nameRubrica={"Approfondimenti per lo studio del buddismo"}
                slug={"/c/approfondimenti-per-lo-studio-del-buddismo"}
                customClass={"order-4 print:order-4"}
              />
            </div>
          </div>
        </section>
        <section className="py-20 lg:py-28 print:hidden">
          <div className="w-full">
            <TitleLoop
              titleLoop={"Chiavi di (s)volta"}
              customClass={
                "mb-20 lg:mb-32 text-3xl sm:text-4xl text-center font-bold text-black md:text-6xl"
              }
            />
          </div>
          <div className="carousel-wrapper print:hidden">
            <CarouselLoop carouselLoop={chiaviDiSvolta} />
          </div>
          <ArrowRight
            href={`/c/chiavi-di-svolta`}
            color={"black"}
            message={"Vedi tutti gli articoli"}
            divStyles={"justify-center flex max-sm:pt-6 pt-14"}
          />
        </section>
        <section className="bg-[#eaebec] pb-28 pt-32 print:pb-10 print:pt-0">
          <div className="relative mx-auto max-w-section px-4 after:border-lightGray md:after:absolute md:after:bottom-0 md:after:left-2/4 md:after:top-0 md:after:w-2 md:after:translate-x-[calc(50%)] md:after:border-l md:after:content-[''] lg:pb-16 print:py-0 print:after:hidden">
            <div className="mx-auto grid grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-24 md:py-10 lg:gap-x-48 print:grid-cols-1">
              <div className="order-1 print:order-1">
                <TitleLoop
                  titleLoop={"Invito alla Lettura"}
                  customClass={"text-3xl font-bold text-black"}
                />
                <span className="text-sm text-lightGray">
                  I dialoghi di Daisaku Ikeda
                </span>
              </div>
              <Rubriche
                rubrica={letture}
                nameRubrica={"Invito alla Lettura"}
                slug={`/c/invito-alla-lettura`}
                customClass={
                  "order-2 md:order-3 md:border-b-0 border-b border-lightGray pb-16 md:pb-0 print:order-2"
                }
              />
              <TitleLoop
                titleLoop={"Recensioni"}
                customClass={
                  "order-3 text-3xl font-bold text-black md:order-2 print:order-3"
                }
              />

              <Rubriche
                rubrica={recensioni}
                nameRubrica={"recensioni"}
                slug={`/c/recensioni`}
                customClass={"order-4 print:order-4"}
              />
            </div>
          </div>
        </section>
        <section className="py-28 print:py-10">
          <div className="mx-auto max-w-box text-center">
            <Image
              className="mx-auto"
              src={gommaCarboncino}
              width={400}
              height={400}
              alt={"gommaCarboncino"}
            ></Image>

            <div className="py-6">
              <Link
                className="bg-orange px-20 py-4 uppercase text-white"
                href={"/c/le-avventure-di-gomma-e-carboncino"}
              >
                Vai
              </Link>
            </div>
          </div>
        </section>
        <section className="bg-[#4e5460] py-28 text-white print:hidden">
          <div className="w-full">
            <TitleLoop
              titleLoop={"Proposte di pace"}
              customClass={
                "mb-32 text-3xl sm:text-4xl text-center font-bold md:text-6xl"
              }
            />
          </div>
          <div className="carousel-wrapper print:hidden">
            <CarouselLoop carouselLoop={proposteDiPace} />
          </div>
          <ArrowRight
            href={"/c/proposta-di-pace"}
            color={"white"}
            message={"Vedi tutte le proposte di pace"}
            divStyles={"justify-center flex max-sm:pt-6 pt-14"}
          />
        </section>
        <section className="px-4 py-20 sm:px-16 lg:px-24 xl:px-32 print:py-10">
          <div className="w-full max-sm:mb-14 sm:mb-32">
            <TitleLoop
              titleLoop={"Scopri BS"}
              customClass={
                "mb-4 text-5xl sm:text-4xl sm:text-center md:text-4xl uppercase font-light tracking-widest text-black"
              }
            />
            <p className="mx-auto text-xl sm:text-balance sm:text-center">
              Un esempio di contenuti di “Buddismo e società” disponibili per
              chi non ha ancora l’abbonamento
            </p>
          </div>
          <div className="grid grid-cols-1 gap-x-20 gap-y-14 sm:grid-cols-2 xl:grid-cols-4 print:grid-cols-2">
            <ContenutiAperti contenutiAperti={contenutiAperti} />
          </div>
        </section>
      </Layout>
    </>
  );
};

export default Home;

export const getStaticProps = async (_context: GetStaticPropsContext) => {
  const loopsInfo = await getLoops([
    10, 30, 70, 80, 90, 100, 110, 120, 130, 20,
  ]);
  const loopsInfoSpeciali = await getLoops([60]);
  const loopsInfoCat = await getLoopsCat([40, 50]);
  const numeroCorrente = await gePreviousNumbersForHome();
  const [
    evidenza,
    studio,
    parole,
    approfondimenti,
    interviste,
    letture,
    recensioni,
    proposteDiPace,
    contenutiAperti,
    chiaviDiSvolta,
  ] = await Promise.all(loopsInfo.map((loop) => getHomepageLoopPosts(loop)));

  const [articlesNumeroCorrente, imgCurrentNumber, InfoCurrentNumber] =
    await Promise.all([
      getArticleFromNumber(numeroCorrente[0].slug as string),
      getImgageCurrentNumbers(Number(numeroCorrente[0].term_id)),
      getInfoCurrentNumbers(Number(numeroCorrente[0].term_id)),
    ]);
  InfoCurrentNumber[0].img = imgCurrentNumber.length
    ? imgCurrentNumber[0].img
    : null;
  const [insegnamentiOrali, editoriale] = await Promise.all(
    loopsInfoCat.map((loop) => getHomepageLoopCatPosts(loop))
  );

  const postsIds = [
    ...evidenza,
    ...studio,
    ...parole,
    ...approfondimenti,
    ...interviste,
    ...letture,
    ...recensioni,
    ...proposteDiPace,
    ...contenutiAperti,
    ...articlesNumeroCorrente,
    ...chiaviDiSvolta,
  ].map(({ ID }) => ID);

  const cateorySpeciali = await getAllSpecialiForHome(
    loopsInfoSpeciali[0]?.terms_id
  );
  const metadata = await getPostsMetadata(postsIds);
  const thumbnail = postsIds.length > 0 ? await getThumbnail(postsIds) : [];

  const mapPostsForClient = (
    posts: LoopPostReturnType[],
    length = 100,
    isEvidenza = false
  ): ArticlePreviewType[] =>
    posts.map(
      ({
        ID,
        post_title,
        post_name,
        post_excerpt,
        post_content,
        post_date,
        post_status,
      }) => ({
        post_name,
        post_title,
        post_status,
        post_excerpt:
          post_excerpt != "" && post_excerpt != "   "
            ? postExcerpt(
                post_excerpt,
                isEvidenza
                  ? thumbnail[Number(ID)]?.thumbnail
                    ? length
                    : 100
                  : length
              )
            : postExcerpt(
                post_content
                  ?.replace(/<\/?[^>]+(>|$)/g, "")
                  ?.replace(/\[\/{0,1}et_pb_.*\]/g, ""),
                isEvidenza
                  ? thumbnail[Number(ID)]?.thumbnail
                    ? length
                    : 100
                  : length
              ),
        meta: metadata[Number(ID)] != undefined ? metadata[Number(ID)] : null,
        thumbnail:
          thumbnail[Number(ID)]?.thumbnail != null
            ? thumbnail[Number(ID)]?.thumbnail
            : null,
        alt:
          thumbnail[Number(ID)]?.alt != null
            ? thumbnail[Number(ID)]?.alt
            : null,
        post_date: post_date.toISOString(),
      })
    );

  const mapPostsForClientSpeciali = (
    posts: InfoSpeciali[],
    length = 100
  ): InfoSpeciali[] =>
    posts.map(
      ({
        name,
        description,
        slug,
        img,
        numeroName,
        numeroSlug,
        numberDescription,
      }) => ({
        name,
        description: postExcerpt(
          description
            ?.replace(/<\/?[^>]+(>|$)/g, "")
            ?.replace(/\[\/{0,1}et_pb_.*\]/g, ""),
          length
        ),
        slug,
        img,
        numeroName,
        numeroSlug,
        numberDescription,
      })
    );

  return {
    props: {
      evidenza: mapPostsForClient(evidenza, 220, true),
      numeroCorrente: mapPostsForClient(articlesNumeroCorrente),
      studio: mapPostsForClient(studio),
      speciali: mapPostsForClientSpeciali(cateorySpeciali),
      parole: mapPostsForClient(parole, 200),
      approfondimenti: mapPostsForClient(approfondimenti, 200),
      interviste: mapPostsForClient(interviste),
      letture: mapPostsForClient(letture),
      recensioni: mapPostsForClient(recensioni),
      proposteDiPace: mapPostsForClient(proposteDiPace),
      contenutiAperti: mapPostsForClient(contenutiAperti),
      chiaviDiSvolta: mapPostsForClient(chiaviDiSvolta),
      insegnamentiOrali: insegnamentiOrali[0],
      editoriale: editoriale[0],
      infoCurrentNumber: InfoCurrentNumber[0],
    },
    revalidate: 20 * 60,
  };
};
